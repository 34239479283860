import { clone, filter, first, isEmpty, map } from 'lodash';

const isNotEmpty = (arr = []) => !isEmpty(arr);

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'image' implicitly has an 'any' type.
const transformImage = (image) => (isNotEmpty(image) ? first(image).url : undefined);

const transformMedia = (arr = []) => {
  // @ts-expect-error [🤖 PLEASE FIX 🤖]: Property 'kind' does not exist on type '{}'.
  const { kind, ...media } = first(arr) || {};

  if (kind === 'image') {
    return {
      kind,
      // @ts-expect-error [🤖 PLEASE FIX 🤖]: Property 'title' does not exist on type '{}'.
      alt: media.title,
      // @ts-expect-error [🤖 PLEASE FIX 🤖]: Property 'url' does not exist on type '{}'.
      src: media.url,
    };
  }

  if (kind === 'video') {
    return {
      kind,
      // @ts-expect-error [🤖 PLEASE FIX 🤖]: Property 'url' does not exist on type '{}'.
      src: media.url,
      // @ts-expect-error [🤖 PLEASE FIX 🤖]: Property 'mimeType' does not exist on type '{}'.
      type: media.mimeType,
      // @ts-expect-error [🤖 PLEASE FIX 🤖]: Property 'posterImage' does not exist on type '{}'.
      posterImage: media.posterImage,
      // @ts-expect-error [🤖 PLEASE FIX 🤖]: Property 'captions' does not exist on type '{}'.
      captions: media.captions,
    };
  }

  return undefined;
};

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'ctaButton' implicitly has an 'any' type.
const transformButton = (ctaButton) => {
  // @ts-expect-error [🤖 PLEASE FIX 🤖]: Property 'isEnabled' does not exist on type '{}'.
  const { isEnabled, label, internalEntry, externalUrl, pdfDocument } = first(ctaButton) || {};

  if (!isEnabled) return undefined;

  if (isNotEmpty(internalEntry)) {
    return {
      label,
      // @ts-expect-error [🤖 PLEASE FIX 🤖]: Object is of type 'unknown'.
      href: `/${first(internalEntry).uri}`,
    };
  }

  return {
    label,
    // @ts-expect-error [🤖 PLEASE FIX 🤖]: Object is of type 'unknown'.
    href: isNotEmpty(pdfDocument) ? first(pdfDocument).url : externalUrl,
  };
};

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'contentMatrix' implicitly has an 'any' type.
const transformContentMatrix = (contentMatrix) => {
  const transformedContentMatrix = clone(
    filter(contentMatrix, (contentMatrixBlock) => !!contentMatrixBlock.typeHandle)
  );

  return map(transformedContentMatrix, (contentMatrixBlock) => {
    switch (contentMatrixBlock.typeHandle) {
      case 'contentBlock':
        return contentMatrixBlock;
      case 'contentGridBlock':
        return contentMatrixBlock;
      case 'contentMediaBlock':
        return {
          ...contentMatrixBlock,
          media: isNotEmpty(contentMatrixBlock.media) ? transformMedia(contentMatrixBlock.media) : undefined,
          callToAction: transformButton(contentMatrixBlock.ctaButton),
        };
      case 'pageHeaderBlock':
        return {
          ...contentMatrixBlock,
          image: isNotEmpty(contentMatrixBlock.image)
            ? {
                // @ts-expect-error [🤖 PLEASE FIX 🤖]: Object is of type 'unknown'.
                url: first(contentMatrixBlock.image).url,
                // @ts-expect-error [🤖 PLEASE FIX 🤖]: Object is of type 'unknown'.
                title: first(contentMatrixBlock.image).title,
              }
            : undefined,
          backgroundImage: {
            // @ts-expect-error [🤖 PLEASE FIX 🤖]: Spread types may only be created from object types.
            ...first(contentMatrixBlock.backgroundImage),
            url: transformImage(contentMatrixBlock.backgroundImage),
          },
          category: contentMatrixBlock.preHeading,
        };
      case 'safetyFeatureShowcaseBlock':
        return {
          ...contentMatrixBlock,
          safetyFeature: isNotEmpty(contentMatrixBlock.safetyFeature)
            ? first(contentMatrixBlock.safetyFeature)
            : undefined,
          backgroundImage: isNotEmpty(contentMatrixBlock.backgroundImage)
            ? {
                // @ts-expect-error [🤖 PLEASE FIX 🤖]: Object is of type 'unknown'.
                url: first(contentMatrixBlock.backgroundImage).url,
                // @ts-expect-error [🤖 PLEASE FIX 🤖]: Object is of type 'unknown'.
                description: first(contentMatrixBlock.backgroundImage).title,
              }
            : undefined,
        };
      default:
        return contentMatrixBlock;
    }
  });
};

export { transformContentMatrix };
