import { Box, Container, Grid } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { ALL_ID, POWER_TYPE_MAPPING } from '@/components/discovery-filter/constants';
import { LIFESTYLE_PREFERENCES, SPACE } from '@/constants';
import { getSliderAriaLabelArray } from '@/utils/get-slider-aria-label-array';
import { useGutterSpacing } from '@/hooks/use-gutter-spacing';
import { PrimaryButton } from '@/components/button';
import Checkbox from '@/components/checkbox';
import Tooltip from '@/components/tooltip';
import { H4 } from '@/components/type';

import { tooltipCopy } from './tooltip-copy';
import BodyStyleSelectionGrid from './body-style-selection-grid';
import { priceRangeSliderMarks } from './utils/price-range-slider-marks';
import {
  Header,
  InnerWrapper,
  PreferenceWrapper,
  StyledHeading,
  StyledHeadingWithTopMargin,
  StyledIcon,
  StyledSlider,
  Wrapper,
} from './styles';
import { getFormattedPriceRange } from './utils/build-price-range-heading-text';
import { updatePowerTypeState } from './utils/update-power-type-state';
import { updateLifestyleState } from './utils/update-lifestyle-state';

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Binding element 'preferenceName' implicitly has an 'any' type.
const PreferenceCheckbox = ({ preferenceName, tooltip, handleChange, body, selectedValues, desktopCols = 6 }) => {
  const isChecked =
    preferenceName === ALL_ID ? selectedValues.length === 0 : _.includes(selectedValues, preferenceName);

  return (
    <Grid item {...{ xs: 12, md: desktopCols }}>
      <PreferenceWrapper onClick={() => handleChange(!isChecked)}>
        {/* @ts-expect-error [🤖 PLEASE FIX 🤖]: Type '{ disableRipple: true; handleChange: () => void; label: any; checked: boolean; }' is not assignable to type 'IntrinsicAttributes & Pick<{ handleChange: any; label: any; checked?: boolean | undefined; isMobile?: boolean | undefined; }, "isMobile" | "checked"> & Pick<...> & Pick<...>'. */}
        <Checkbox disableRipple handleChange={() => {}} label={body} checked={isChecked} />
        {tooltip && (
          <StyledIcon>
            <Tooltip title={tooltip} />
          </StyledIcon>
        )}
      </PreferenceWrapper>{' '}
    </Grid>
  );
};

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Binding element 'filterActions' implicitly has an 'any' type.
const DiscoveryQuestionsInput = ({ filterActions, filterState, submitInput }) => {
  const { selectedBodyStyles, selectedLifestyles, selectedPowerTypes = [], uiSelectedPriceRange } = filterState;
  const { updateSelectedBodyStyles, updateSelectedPriceRange, updateUiSelectedPriceRange } = filterActions;

  const [isLoading, setIsLoading] = React.useState(false);

  return (
    <Container maxWidth="xl">
      <Grid container justifyContent="center">
        <Grid item {...{ xs: 12, md: 8, lg: 6 }}>
          <Wrapper>
            <Header as="h2">Browse safe cars on any budget</Header>
            <InnerWrapper>
              <StyledHeading id="price-range-slider">Set your budget range:</StyledHeading>
              <H4>{getFormattedPriceRange(uiSelectedPriceRange, priceRangeSliderMarks)}</H4>
              <StyledSlider
                // @TODO: Replace the Test ID with an ARIA label for better a11y
                data-testid="price-range-slider"
                {...{
                  // @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'index' implicitly has an 'any' type.
                  getAriaLabel: (index) => getSliderAriaLabelArray('Budget')[index],
                  marks: priceRangeSliderMarks,
                  uiSelectedRange: uiSelectedPriceRange,
                  updateUiSelectedRange: updateUiSelectedPriceRange,
                  updateSelectedRange: updateSelectedPriceRange,
                  sliderType: 'budget',
                  disableSelectingSingleValue: true,
                  // @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'value' implicitly has an 'any' type.
                  getAriaValueText: (value) => `$${value}`,
                }}
              />
              <StyledHeadingWithTopMargin>Select your preferred body types:</StyledHeadingWithTopMargin>
              <Box mt={SPACE.X1} />
              <BodyStyleSelectionGrid {...{ updateStateBodyStyles: updateSelectedBodyStyles, selectedBodyStyles }} />
              <StyledHeadingWithTopMargin>Select your lifestyle preferences:</StyledHeadingWithTopMargin>
              <Box mt={SPACE.X1} />
              <Grid container spacing={useGutterSpacing()} justifyContent="center" direction="row">
                <PreferenceCheckbox
                  preferenceName={LIFESTYLE_PREFERENCES.pPlaterApproved}
                  tooltip={tooltipCopy.pPlaterApproved}
                  handleChange={() =>
                    updateLifestyleState({
                      selectedLifestyles,
                      lifestylePreference: LIFESTYLE_PREFERENCES.pPlaterApproved,
                      filterActions,
                    })
                  }
                  body={
                    <>
                      <strong>P-plater approved</strong> (Victoria only)
                    </>
                  }
                  selectedValues={selectedLifestyles}
                />
                <PreferenceCheckbox
                  preferenceName={LIFESTYLE_PREFERENCES.safeCarsForFamilies}
                  tooltip={tooltipCopy.safeCarsForFamilies}
                  handleChange={() =>
                    updateLifestyleState({
                      selectedLifestyles,
                      lifestylePreference: LIFESTYLE_PREFERENCES.safeCarsForFamilies,
                      filterActions,
                    })
                  }
                  body={<strong>Safe cars for families</strong>}
                  selectedValues={selectedLifestyles}
                />
              </Grid>
              <StyledHeadingWithTopMargin>Select your preferred power type:</StyledHeadingWithTopMargin>
              <Grid container spacing={useGutterSpacing()} justifyContent="center" direction="row">
                <PreferenceCheckbox
                  preferenceName={ALL_ID}
                  tooltip={null}
                  handleChange={() => updatePowerTypeState({ selectedPowerTypes, powerType: ALL_ID, filterActions })}
                  body={
                    <>
                      <strong>Any power type</strong>
                    </>
                  }
                  selectedValues={selectedPowerTypes}
                  desktopCols={4}
                />
                {Object.keys(POWER_TYPE_MAPPING).map((powerType) => (
                  <PreferenceCheckbox
                    key={powerType}
                    preferenceName={powerType}
                    tooltip={null}
                    // @ts-expect-error [🤖 PLEASE FIX 🤖]: Type 'string' is not assignable to type 'PowerType'.
                    handleChange={() => updatePowerTypeState({ selectedPowerTypes, powerType, filterActions })}
                    body={<strong>{powerType} vehicles</strong>}
                    selectedValues={selectedPowerTypes}
                    desktopCols={4}
                  />
                ))}
              </Grid>
              <Box mt={SPACE.X2} />
              <Grid container spacing={useGutterSpacing()} justifyContent="center" direction="row">
                <Grid item {...{ xs: 12, md: 10 }}>
                  <PrimaryButton
                    fullWidth
                    {...{
                      isLoading,
                      onClick: () => {
                        setIsLoading(true);
                        submitInput(filterState);
                      },
                    }}
                  >
                    Browse safe options
                  </PrimaryButton>
                </Grid>
              </Grid>
              <Box mt={SPACE.X2} />
            </InnerWrapper>
          </Wrapper>
        </Grid>
      </Grid>
    </Container>
  );
};

DiscoveryQuestionsInput.propTypes = {
  filterActions: PropTypes.shape({
    addLifestyle: PropTypes.func.isRequired,
    removeLifestyle: PropTypes.func.isRequired,
    updateSelectedBodyStyles: PropTypes.func.isRequired,
    updateSelectedPriceRange: PropTypes.func.isRequired,
    updateUiSelectedPriceRange: PropTypes.func.isRequired,
  }).isRequired,
  filterState: PropTypes.shape({
    // @ts-expect-error [🤖 PLEASE FIX 🤖]: Argument of type 'StringConstructor' is not assignable to parameter of type 'Validator<unknown>'.
    selectedBodyStyles: PropTypes.arrayOf(String).isRequired,
    // @ts-expect-error [🤖 PLEASE FIX 🤖]: Argument of type 'StringConstructor' is not assignable to parameter of type 'Validator<unknown>'.
    selectedLifestyles: PropTypes.arrayOf(String).isRequired,
    // @ts-expect-error [🤖 PLEASE FIX 🤖]: Argument of type 'NumberConstructor' is not assignable to parameter of type 'Validator<unknown>'.
    uiSelectedPriceRange: PropTypes.arrayOf(Number).isRequired,
  }).isRequired,
  submitInput: PropTypes.func.isRequired,
};

export default DiscoveryQuestionsInput;
export { updateLifestyleState };
