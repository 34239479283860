import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { BODY_STYLES, BODY_STYLE_KEYS } from '../../../constants/body-styles';
import { logTrackingEvent } from '../../../utils/tracking';
import { useGutterSpacing } from '../../../hooks/use-gutter-spacing';

import { BodyStyleWrapper, Image, ImageWrapper, Label, List } from './styles';

// This function will convert the body styles array in redux to an object which will drive the body styles selection grid
// ['hatch', 'sport'] becomes { 'hatch': true, 'sport': true }
// @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'selectedBodyStyles' implicitly has an 'any' type.
const convertStateArrayToUIObject = (selectedBodyStyles) => {
  if (_.isEmpty(selectedBodyStyles)) {
    return { [BODY_STYLE_KEYS.all]: true };
  }
  return _.mapValues(_.mapKeys(selectedBodyStyles), () => true);
};

const handleSelectedBodyStyle = (
  // @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'bodyStyleKey' implicitly has an 'any' type.
  bodyStyleKey,
  // @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'uiSelectedBodyStyles' implicitly has an 'any' type.
  uiSelectedBodyStyles,
  // @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'setUISelectedBodyStyles' implicitly has an 'any' type.
  setUISelectedBodyStyles,
  // @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'updateStateBodyStyles' implicitly has an 'any' type.
  updateStateBodyStyles
) => {
  const existingValueForKey = uiSelectedBodyStyles[bodyStyleKey];
  const newSelectedBodyStyles = { ...uiSelectedBodyStyles, [bodyStyleKey]: !existingValueForKey };

  if (!existingValueForKey) {
    logTrackingEvent({
      Category: 'Discovery',
      Action: 'BodyStyle:Selected',
      Label: bodyStyleKey,
    });
  }

  const isAnyBodyStyleSelected = _.includes(newSelectedBodyStyles, true);
  if (!isAnyBodyStyleSelected) {
    return;
  }

  const wasAllTypesJustSelected =
    newSelectedBodyStyles[BODY_STYLE_KEYS.all] && !uiSelectedBodyStyles[BODY_STYLE_KEYS.all];
  if (wasAllTypesJustSelected) {
    setUISelectedBodyStyles({ [BODY_STYLE_KEYS.all]: true });
    updateStateBodyStyles([]);
    return;
  }
  const selectedBodyStylesObj = { ...newSelectedBodyStyles, [BODY_STYLE_KEYS.all]: undefined };
  setUISelectedBodyStyles(selectedBodyStylesObj);
  updateStateBodyStyles(_.filter(BODY_STYLE_KEYS, (key) => !!selectedBodyStylesObj[key]));
};

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Binding element 'selectedBodyStyles' implicitly has an 'any' type.
const BodyStyleSelectionGrid = ({ selectedBodyStyles, updateStateBodyStyles }) => {
  const [uiSelectedBodyStyles, setUISelectedBodyStyles] = React.useState(
    convertStateArrayToUIObject(selectedBodyStyles)
  );

  return (
    // @ts-expect-error [🤖 PLEASE FIX 🤖]: Type '{ children: Element[]; gridColumnGap: number; }' is not assignable to type 'IntrinsicAttributes & { theme?: Theme | undefined; as?: ElementType<any, keyof IntrinsicElements> | undefined; } & ClassAttributes<...> & HTMLAttributes<...>'.
    <List gridColumnGap={useGutterSpacing()}>
      {_.map(BODY_STYLE_KEYS, (bodyStyleKey) => {
        const { imageUrl, label } = BODY_STYLES[bodyStyleKey];
        const isSelected = !!uiSelectedBodyStyles[bodyStyleKey];
        const onClick = () =>
          handleSelectedBodyStyle(bodyStyleKey, uiSelectedBodyStyles, setUISelectedBodyStyles, updateStateBodyStyles);

        // @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'event' implicitly has an 'any' type.
        const onKeyDown = (event) => {
          if (event.key === ' ') {
            event.preventDefault();
            onClick();
          }
        };
        return (
          <li key={bodyStyleKey}>
            <BodyStyleWrapper
              {...{ isSelected, onClick, tabIndex: 0, onKeyDown, role: 'button', 'aria-pressed': isSelected }}
            >
              <ImageWrapper>
                <Image src={imageUrl} alt="" draggable={false} />
              </ImageWrapper>
              <Label>{label}</Label>
            </BodyStyleWrapper>
          </li>
        );
      })}
    </List>
  );
};

BodyStyleSelectionGrid.propTypes = {
  // @ts-expect-error [🤖 PLEASE FIX 🤖]: Argument of type 'NumberConstructor' is not assignable to parameter of type 'Validator<unknown>'.
  selectedBodyStyles: PropTypes.arrayOf(Number).isRequired,
  updateStateBodyStyles: PropTypes.func.isRequired,
};

export default BodyStyleSelectionGrid;
export { handleSelectedBodyStyle };
