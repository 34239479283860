import { first, last } from 'lodash';

import { convertToCurrencyFormat } from '../../../../utils/convert-to-currency-format';

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'selectedPriceRange' implicitly has an 'any' type.
const getFormattedPriceRange = (selectedPriceRange, priceRangeSliderMarks) => {
  const selectedPriceRangeStartText = convertToCurrencyFormat(first(selectedPriceRange));

  // @ts-expect-error [🤖 PLEASE FIX 🤖]: Object is of type 'unknown'.
  const plusSuffix = last(selectedPriceRange) === last(priceRangeSliderMarks).value ? '+' : '';
  const selectedPriceRangeEndText = `${convertToCurrencyFormat(last(selectedPriceRange))}${plusSuffix}`;

  return `$${selectedPriceRangeStartText} to $${selectedPriceRangeEndText}`;
};

export { getFormattedPriceRange };
