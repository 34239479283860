import { ALL_ID } from '@/components/discovery-filter/constants';

import type { FilterActions } from '@/types/filters';
import type { PowerType } from './types';

interface UpdatePowerTypeStateParams {
  selectedPowerTypes: PowerType[];
  powerType: PowerType;
  filterActions: FilterActions;
}

export const updatePowerTypeState = ({ selectedPowerTypes, powerType, filterActions }: UpdatePowerTypeStateParams) => {
  if (powerType === ALL_ID) {
    return filterActions.updatePowerTypes([]);
  }
  if (selectedPowerTypes.includes(powerType)) {
    return filterActions.removePowerType(powerType);
  }

  filterActions.addPowerType(powerType);
};
