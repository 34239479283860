import styled from '@emotion/styled';

import { BREAKPOINTS, COLORS, GLOBAL, SPACE } from '../../../constants';
import { Small } from '../../type';
import { shouldForwardProp } from '../../../utils/should-forward-prop';

/*
  We're using box-shadow here as a border, since we have to go from 0px border to 1px, then to 3px.
  We can't use "border" as it will change the size of the div causing the box to jump around.
  We can't use "outline" because it doesn't have a border-radius equivalent.
*/
const HOVER_BORDER = `box-shadow: 0 0 0 1px ${COLORS.GREY_FOUR};`;
const SELECTED_BORDER = `box-shadow: 0 0 0 3px ${COLORS.BRAND_BLUE_SIX};`;

const BodyStyleWrapper = styled('div', { shouldForwardProp })`
  border-radius: 0;
  user-select: none;
  padding: ${SPACE.X1} ${SPACE.X0};
  text-align: center;

  :active {
    -webkit-tap-highlight-color: transparent;
  }

  :hover {
    cursor: pointer;
  }

  :focus-visible {
    color: ${COLORS.BRAND_BLUE_EIGHT};
  }

  :focus:not(:focus-visible) {
    outline: none;
  }

  ${
    /* @ts-expect-error [🤖 PLEASE FIX 🤖]: Property 'isSelected' does not exist on type '{ theme?: Theme | undefined; as?: ElementType<any, keyof IntrinsicElements> | undefined; } & ClassAttributes<HTMLDivElement> & HTMLAttributes<...> & { ...; }'. */
    ({ isSelected }) => {
      if (isSelected) {
        return `
        ${SELECTED_BORDER}
      `;
      }
      return `
      &:hover, &:focus {
        ${HOVER_BORDER}
      }
    `;
    }
  }
`;

const Label = styled(Small)`
  font-family: ${GLOBAL.BRAND_FONT_FAMILY_SECOND};
`;

const Image = styled.img`
  max-width: 120px;
  max-height: 50px;
  width: 100%;
  height: 100%;
  margin-bottom: ${SPACE.X1};
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const List = styled('ul', { shouldForwardProp })`
  display: grid;

  grid-template-columns: auto auto;
  grid-gap: ${
    /* @ts-expect-error [🤖 PLEASE FIX 🤖]: Property 'gridColumnGap' does not exist on type '{ theme?: Theme | undefined; as?: ElementType<any, keyof IntrinsicElements> | undefined; } & ClassAttributes<HTMLUListElement> & HTMLAttributes<...> & { ...; }'. */
    ({ gridColumnGap }) => {
      switch (gridColumnGap) {
        case 3:
          return '12px';
        case 4:
          return '16px';
        case 5:
          return '20px';
        case 6:
          return '24px';
        case 2:
        default:
          return '8px';
      }
    }
  };
  width: 90%;
  padding: 0;
  margin: 0;
  list-style-type: none;

  @media (min-width: ${BREAKPOINTS.MD}px) {
    grid-template-columns: auto auto auto;
  }
`;

export { BodyStyleWrapper, Label, Image, List, ImageWrapper };
