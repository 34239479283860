import _ from 'lodash';

import type { FilterActions } from '@/types/filters';

interface UpdateLifestyleStateParams {
  selectedLifestyles: string[];
  lifestylePreference: string;
  filterActions: FilterActions;
}

export const updateLifestyleState = ({
  selectedLifestyles,
  lifestylePreference,
  filterActions,
}: UpdateLifestyleStateParams) => {
  if (!_.includes(selectedLifestyles, lifestylePreference)) {
    filterActions.addLifestyle(lifestylePreference);
  } else {
    filterActions.removeLifestyle(lifestylePreference);
  }
};
