import algoliasearch from 'algoliasearch';
import Router from 'next/router';
import React from 'react';
import { InstantSearch } from 'react-instantsearch-dom';
import styled from '@emotion/styled';
import _ from 'lodash';
import { connect } from 'react-redux';

import ContentMatrix from '../components/content-matrix';
import DiscoveryQuestionsInput from '../components/discovery-questions-input';
import Layout from '../components/layout';
import Search from '../components/search';
import { BREAKPOINTS, CACHE, GLOBAL, PAGE_SLUGS, SECTION_HANDLES, URLS } from '../constants';
import { fetch } from '../utils/api';
import { transformContentMatrix } from '../utils/transform';
import { withAppProps, withAppProviders } from '../utils/withAppProps';

const searchClient = algoliasearch(
  `${process.env.NEXT_PUBLIC_ALGOLIA_APPLICATION_ID}`,
  `${process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY}`,
  {}
);

const StyledLayout = styled(Layout)`
  background-image: url(${(props) => props.secondaryBackgroundImageUrl});
  background-repeat: no-repeat;
  background-size: contain;
  @media (min-width: ${BREAKPOINTS.LG}px) {
    background-position: center 96px;
    background-image: url(${(props) => props.backgroundImageUrl});
  }
`;

const StyledHeading = styled.h1`
  display: flex;
  font-family: ${GLOBAL.BRAND_FONT_FAMILY_SECOND};
  justify-content: center;
  margin-top: 38px;
  text-align: center;

  & span {
    font-family: ${GLOBAL.BRAND_FONT_FAMILY};
  }
`;

const navigateToDiscoveryResults = () => {
  Router.push(URLS.RESULTS);
};

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'stateValues' implicitly has an 'any' type.
const submitInput = (stateValues) => navigateToDiscoveryResults(stateValues);

const Home = ({
  // @ts-expect-error [🤖 PLEASE FIX 🤖]: Binding element 'backgroundImageUrl' implicitly has an 'any' type.
  backgroundImageUrl,
  // @ts-expect-error [🤖 PLEASE FIX 🤖]: Binding element 'secondaryBackgroundImageUrl' implicitly has an 'any' type.
  secondaryBackgroundImageUrl,
  // @ts-expect-error [🤖 PLEASE FIX 🤖]: Binding element 'contentMatrix' implicitly has an 'any' type.
  contentMatrix,
  // @ts-expect-error [🤖 PLEASE FIX 🤖]: Binding element 'compareCarIds' implicitly has an 'any' type.
  compareCarIds,
  // @ts-expect-error [🤖 PLEASE FIX 🤖]: Binding element 'filterActions' implicitly has an 'any' type.
  filterActions,
  // @ts-expect-error [🤖 PLEASE FIX 🤖]: Binding element 'filterState' implicitly has an 'any' type.
  filterState,
}) => {
  const transformedContentMatrix = transformContentMatrix(contentMatrix);
  return (
    <StyledLayout
      isHomePage
      backgroundImageUrl={backgroundImageUrl}
      secondaryBackgroundImageUrl={secondaryBackgroundImageUrl}
      compareCarIds={compareCarIds}
    >
      <StyledHeading>
        <span>how</span>
        safe
        <span>is</span>
        your
        <span>car?</span>
      </StyledHeading>
      {/* @ts-expect-error [🤖 PLEASE FIX 🤖]: No overload matches this call. */}
      <InstantSearch indexName={process.env.NEXT_PUBLIC_ALGOLIA_INDEX_NAME} searchClient={searchClient}>
        <Search />
      </InstantSearch>
      <DiscoveryQuestionsInput submitInput={submitInput} filterActions={filterActions} filterState={filterState} />
      {/* @ts-expect-error [🤖 PLEASE FIX 🤖]: Type 'any[]' is not assignable to type 'never[]'. */}
      <ContentMatrix blocks={transformedContentMatrix} />
    </StyledLayout>
  );
};

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'ctx' implicitly has an 'any' type.
const getStaticProps = withAppProps(async (ctx) => {
  const { preview, previewData } = ctx;
  const contentQuery = `{
    entries(slug: "${PAGE_SLUGS.HOME}") {
      ... on ${SECTION_HANDLES.HOME}_${SECTION_HANDLES.HOME}_Entry {
        backgroundImage {
          url
        }
        secondaryBackgroundImage {
          url
        }
        ${ContentMatrix.query}
      }
    }
  }`;
  const contentQueryResponse = await fetch(
    // @ts-expect-error [🤖 PLEASE FIX 🤖]: Object literal may only specify known properties, and 'query' does not exist in type '{ method: string; }'.
    { query: contentQuery },
    { params: { token: preview ? previewData?.previewToken : undefined } }
  );

  const { backgroundImage, secondaryBackgroundImage, contentMatrix } = contentQueryResponse.data.data.entries[0];
  const backgroundImageUrl = _.get(backgroundImage, '[0].url');
  const secondaryBackgroundImageUrl = _.get(secondaryBackgroundImage, '[0].url');

  return {
    props: {
      backgroundImageUrl,
      secondaryBackgroundImageUrl,
      contentMatrix,
    },
    revalidate: CACHE.REVALIDATE,
  };
});

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'state' implicitly has an 'any' type.
const mapState = (state) => ({
  filterState: {
    selectedPriceRange: state.filters.selectedPriceRange,
    uiSelectedPriceRange: state.filters.uiSelectedPriceRange,
    selectedBodyStyles: state.filters.selectedBodyStyles,
    selectedLifestyles: state.filters.selectedLifestyles,
    selectedPowerTypes: state.filters.selectedPowerTypes,
  },
});

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'dispatch' implicitly has an 'any' type.
const mapDispatch = (dispatch) => ({
  filterActions: {
    addLifestyle: dispatch.filters.addLifestyle,
    removeLifestyle: dispatch.filters.removeLifestyle,
    updateSelectedBodyStyles: dispatch.filters.updateSelectedBodyStyles,
    updateSelectedPriceRange: dispatch.filters.updateSelectedPriceRange,
    updateUiSelectedPriceRange: dispatch.filters.updateUiSelectedPriceRange,
    addPowerType: dispatch.filters.addPowerType,
    removePowerType: dispatch.filters.removePowerType,
    updatePowerTypes: dispatch.filters.updatePowerTypes,
  },
});

export default withAppProviders(connect(mapState, mapDispatch)(Home));
export { getStaticProps };
